angular.module('app').filter('complianceMode', function () {
    return function (complianceMode) {
        var text;

        if (_.isUndefined(complianceMode)) return text;

        switch (complianceMode) {

            case "AUDIT":
                text = "Audit";
                break;
            case "WARN":
                text = "Warning";
                break;
            case "DISALLOW":
                text = "Disallow";
                break;
            default:
                text = "n/a";
        }

        return text;

    };
});